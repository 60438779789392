import { default as editPWGRYuJ7ekMeta } from "/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id]/edit.vue?macro=true";
import { default as indexdBWd3mZTQmMeta } from "/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id]/index.vue?macro=true";
import { default as _91account_plan_id_93eKkkB7vMEVMeta } from "/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id].vue?macro=true";
import { default as editkYrWCHZQwyMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexTcuLHsQQ3eMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93SEJGfYTgZdMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indextpXKCaQwfyMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans/index.vue?macro=true";
import { default as newiWb9a0clUvMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plans5vdBZDs9GIMeta } from "/codebuild/output/src77872522/src/pages/admin/account_requested_plans.vue?macro=true";
import { default as editBWR8AIpX4IMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue?macro=true";
import { default as indexX2KJj0n2nlMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue?macro=true";
import { default as _91account_requested_plan_id_93Lp2jDSKpmAMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue?macro=true";
import { default as indexQjvE8NnxgAMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue?macro=true";
import { default as newL1vbGvkDdXMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue?macro=true";
import { default as account_requested_plansfUxeiwy30sMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans.vue?macro=true";
import { default as editO6hoOLnRcLMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexm2Fw7VIYNEMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93PzLJRO7FVTMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue?macro=true";
import { default as index1pUbYFngHIMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/index.vue?macro=true";
import { default as new1EEdQTaPR4Meta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/new.vue?macro=true";
import { default as caller_idsQfP44mQrxnMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids.vue?macro=true";
import { default as editTFFByvvE8pMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/edit.vue?macro=true";
import { default as index7LAVJrLVzjMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/index.vue?macro=true";
import { default as edit8be7oh5GcuMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexMNpBywGyU2Meta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_937qvIwDkynvMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexfsd52rfuLUMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue?macro=true";
import { default as newBatBYAg2imMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsdy6KKrrGwMMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings.vue?macro=true";
import { default as _91account_id_93vhrOD7SWfhMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/[account_id].vue?macro=true";
import { default as indexUk63cPww4qMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/index.vue?macro=true";
import { default as wizard_plan_selectionj4tZohUzNnMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/wizard_plan_selection.vue?macro=true";
import { default as wizardr64n0OTo3uMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts/wizard.vue?macro=true";
import { default as accountsp3RB3XgI6AMeta } from "/codebuild/output/src77872522/src/pages/admin/accounts.vue?macro=true";
import { default as editHSkgWUYGgXMeta } from "/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id]/edit.vue?macro=true";
import { default as indexRy5OAbiDVWMeta } from "/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id]/index.vue?macro=true";
import { default as _91admin_user_id_93U1SqOdECv3Meta } from "/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id].vue?macro=true";
import { default as indexmOjFvpVr8IMeta } from "/codebuild/output/src77872522/src/pages/admin/admin_users/index.vue?macro=true";
import { default as newPWDzCSXZZ9Meta } from "/codebuild/output/src77872522/src/pages/admin/admin_users/new.vue?macro=true";
import { default as admin_usersEwHyO7tZoxMeta } from "/codebuild/output/src77872522/src/pages/admin/admin_users.vue?macro=true";
import { default as indexMKewOiFFnZMeta } from "/codebuild/output/src77872522/src/pages/admin/audit_trails/index.vue?macro=true";
import { default as audit_trails9QlENXk9BiMeta } from "/codebuild/output/src77872522/src/pages/admin/audit_trails.vue?macro=true";
import { default as editoeESQAbnedMeta } from "/codebuild/output/src77872522/src/pages/admin/billings/[billing_id]/edit.vue?macro=true";
import { default as indexuM7EVeYS7IMeta } from "/codebuild/output/src77872522/src/pages/admin/billings/[billing_id]/index.vue?macro=true";
import { default as _91billing_id_93UJzBePCElVMeta } from "/codebuild/output/src77872522/src/pages/admin/billings/[billing_id].vue?macro=true";
import { default as indext5flcA4kzNMeta } from "/codebuild/output/src77872522/src/pages/admin/billings/index.vue?macro=true";
import { default as newDVy90s9Gu8Meta } from "/codebuild/output/src77872522/src/pages/admin/billings/new.vue?macro=true";
import { default as billingsLBjRSR8QCZMeta } from "/codebuild/output/src77872522/src/pages/admin/billings.vue?macro=true";
import { default as editAM4jOM8q3sMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id]/edit.vue?macro=true";
import { default as indexxXVhSbPKTAMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id]/index.vue?macro=true";
import { default as _91caller_id_id_93wm2AQytXccMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id].vue?macro=true";
import { default as indexYw5Ha6yhfrMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids/index.vue?macro=true";
import { default as newEf3q2SMekhMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids/new.vue?macro=true";
import { default as caller_ids6Rpy7UoohtMeta } from "/codebuild/output/src77872522/src/pages/admin/caller_ids.vue?macro=true";
import { default as editGaaTlEoPfkMeta } from "/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue?macro=true";
import { default as indexxTidS3RpbYMeta } from "/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue?macro=true";
import { default as _91campaign_setting_id_93fAWGfcDP8LMeta } from "/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id].vue?macro=true";
import { default as editcBRY9JFL8oMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/edit.vue?macro=true";
import { default as index43YG92QblaMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/index.vue?macro=true";
import { default as edit515jWxMHEIMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue?macro=true";
import { default as indexf6fTg6lIR4Meta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue?macro=true";
import { default as _91outbound_call_transaction_id_9329cvb5itgZMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue?macro=true";
import { default as indexvPlpPib0uDMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue?macro=true";
import { default as newHKycfo6yYrMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue?macro=true";
import { default as outbound_call_transactionsWbnYvNcguGMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue?macro=true";
import { default as _91campaign_id_936A8v6NvYt4Meta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id].vue?macro=true";
import { default as index3SeP6to11rMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/index.vue?macro=true";
import { default as newDHTihawExxMeta } from "/codebuild/output/src77872522/src/pages/admin/campaigns/new.vue?macro=true";
import { default as campaignsqF1qMn7bL0Meta } from "/codebuild/output/src77872522/src/pages/admin/campaigns.vue?macro=true";
import { default as indexHzlrOiMp1XMeta } from "/codebuild/output/src77872522/src/pages/admin/custom_emails/[custom_email_id]/index.vue?macro=true";
import { default as _91custom_email_id_93goyPqs8XzdMeta } from "/codebuild/output/src77872522/src/pages/admin/custom_emails/[custom_email_id].vue?macro=true";
import { default as indexokPEUCs8l7Meta } from "/codebuild/output/src77872522/src/pages/admin/custom_emails/index.vue?macro=true";
import { default as newutxkkO9qYPMeta } from "/codebuild/output/src77872522/src/pages/admin/custom_emails/new.vue?macro=true";
import { default as custom_emailsQM0CyevzSfMeta } from "/codebuild/output/src77872522/src/pages/admin/custom_emails.vue?macro=true";
import { default as indexRlxJzvFcDeMeta } from "/codebuild/output/src77872522/src/pages/admin/dashboard/index.vue?macro=true";
import { default as dashboard9IcS2NYvnyMeta } from "/codebuild/output/src77872522/src/pages/admin/dashboard.vue?macro=true";
import { default as index3StMTQ9j1ZMeta } from "/codebuild/output/src77872522/src/pages/admin/inquiries/[inquiry_id]/index.vue?macro=true";
import { default as _91inquiry_id_93WCsY8k6x0AMeta } from "/codebuild/output/src77872522/src/pages/admin/inquiries/[inquiry_id].vue?macro=true";
import { default as indexJpnz3lZDIjMeta } from "/codebuild/output/src77872522/src/pages/admin/inquiries/index.vue?macro=true";
import { default as newIIjLXaJWkQMeta } from "/codebuild/output/src77872522/src/pages/admin/inquiries/new.vue?macro=true";
import { default as inquiriesWCjEGPdeblMeta } from "/codebuild/output/src77872522/src/pages/admin/inquiries.vue?macro=true";
import { default as indexaecGqEenMQMeta } from "/codebuild/output/src77872522/src/pages/admin/login/index.vue?macro=true";
import { default as loginD4TrqyZLtFMeta } from "/codebuild/output/src77872522/src/pages/admin/login.vue?macro=true";
import { default as index0iKiPPdpILMeta } from "/codebuild/output/src77872522/src/pages/admin/password/edit/index.vue?macro=true";
import { default as index7gPgjfjhVOMeta } from "/codebuild/output/src77872522/src/pages/admin/password/new/index.vue?macro=true";
import { default as indexnuahQpaYo1Meta } from "/codebuild/output/src77872522/src/pages/admin/reports/index.vue?macro=true";
import { default as reportsaMWgpMfDd6Meta } from "/codebuild/output/src77872522/src/pages/admin/reports.vue?macro=true";
import { default as edit8j7Pim3Ou3Meta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue?macro=true";
import { default as indexhqle2kyvKVMeta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue?macro=true";
import { default as _91sms_masking_id_93Mk0fjr6N0wMeta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id].vue?macro=true";
import { default as indexbP4fepu0FgMeta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings/index.vue?macro=true";
import { default as newg37jdDkLIlMeta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings/new.vue?macro=true";
import { default as sms_maskingsFJfvT3c8s2Meta } from "/codebuild/output/src77872522/src/pages/admin/sms_maskings.vue?macro=true";
import { default as editGDfsfbtfFLMeta } from "/codebuild/output/src77872522/src/pages/admin/users/[user_id]/edit.vue?macro=true";
import { default as indexjcT5gFSpW1Meta } from "/codebuild/output/src77872522/src/pages/admin/users/[user_id]/index.vue?macro=true";
import { default as _91user_id_932dyGTFt8U8Meta } from "/codebuild/output/src77872522/src/pages/admin/users/[user_id].vue?macro=true";
import { default as index7DIu9MCuWBMeta } from "/codebuild/output/src77872522/src/pages/admin/users/index.vue?macro=true";
import { default as newXqoyuS2B0sMeta } from "/codebuild/output/src77872522/src/pages/admin/users/new.vue?macro=true";
import { default as usersmVr851jO3YMeta } from "/codebuild/output/src77872522/src/pages/admin/users.vue?macro=true";
import { default as editJHI75mK9rUMeta } from "/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id]/edit.vue?macro=true";
import { default as indexXuuepJHAXYMeta } from "/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id]/index.vue?macro=true";
import { default as _91wallet_id_93xLq1VeTySDMeta } from "/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id].vue?macro=true";
import { default as admind3Umya4o0QMeta } from "/codebuild/output/src77872522/src/pages/admin.vue?macro=true";
import { default as indexsByaFbKcXEMeta } from "/codebuild/output/src77872522/src/pages/audit_logs/index.vue?macro=true";
import { default as indexFFMQWeWY9GMeta } from "/codebuild/output/src77872522/src/pages/billings/index.vue?macro=true";
import { default as indexboWsqWCrzmMeta } from "/codebuild/output/src77872522/src/pages/call_out/reports/index.vue?macro=true";
import { default as _91id_935mjs8IcuawMeta } from "/codebuild/output/src77872522/src/pages/call_recordings/[id].vue?macro=true";
import { default as indexiJAUiY3rPtMeta } from "/codebuild/output/src77872522/src/pages/campaigns/[id]/index.vue?macro=true";
import { default as indexzIBthkneu5Meta } from "/codebuild/output/src77872522/src/pages/campaigns/index.vue?macro=true";
import { default as indexHMdhTRooqaMeta } from "/codebuild/output/src77872522/src/pages/dashboard/index.vue?macro=true";
import { default as indexjbeRnpRnd7Meta } from "/codebuild/output/src77872522/src/pages/help/index.vue?macro=true";
import { default as index1XhcxyfxByMeta } from "/codebuild/output/src77872522/src/pages/index.vue?macro=true";
import { default as indexGljENlFDjDMeta } from "/codebuild/output/src77872522/src/pages/inquiry/index.vue?macro=true";
import { default as editorkCHpyu9ZtmMeta } from "/codebuild/output/src77872522/src/pages/ivr_trees/[id]/editor.vue?macro=true";
import { default as indexSBEAN7AJecMeta } from "/codebuild/output/src77872522/src/pages/ivr_trees/index.vue?macro=true";
import { default as verify_emailtRIpfTyIrcMeta } from "/codebuild/output/src77872522/src/pages/profiles/[user_id]/verify_email.vue?macro=true";
import { default as welcome_dashboardO6rA3dnyHlMeta } from "/codebuild/output/src77872522/src/pages/profiles/[user_id]/welcome_dashboard.vue?macro=true";
import { default as indexPIegKiarypMeta } from "/codebuild/output/src77872522/src/pages/profiles/index.vue?macro=true";
import { default as index7XYe9GPNmCMeta } from "/codebuild/output/src77872522/src/pages/sub_users/users/index.vue?macro=true";
import { default as indexH20yTe832lMeta } from "/codebuild/output/src77872522/src/pages/terms_and_conditions/index.vue?macro=true";
import { default as indexO4Lzx5U6ArMeta } from "/codebuild/output/src77872522/src/pages/users/password/edit/index.vue?macro=true";
import { default as indexWn9KwqF9g4Meta } from "/codebuild/output/src77872522/src/pages/users/password/new/index.vue?macro=true";
import { default as indexbzDhWR96yRMeta } from "/codebuild/output/src77872522/src/pages/users/sign_in/index.vue?macro=true";
export default [
  {
    name: admind3Umya4o0QMeta?.name ?? "admin",
    path: admind3Umya4o0QMeta?.path ?? "/admin",
    children: [
  {
    path: _91account_plan_id_93eKkkB7vMEVMeta?.path ?? "account_plans/:account_plan_id()",
    children: [
  {
    name: editPWGRYuJ7ekMeta?.name ?? "admin-account_plans-account_plan_id-edit",
    path: editPWGRYuJ7ekMeta?.path ?? "edit",
    meta: editPWGRYuJ7ekMeta || {},
    alias: editPWGRYuJ7ekMeta?.alias || [],
    redirect: editPWGRYuJ7ekMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdBWd3mZTQmMeta?.name ?? "admin-account_plans-account_plan_id",
    path: indexdBWd3mZTQmMeta?.path ?? "",
    meta: indexdBWd3mZTQmMeta || {},
    alias: indexdBWd3mZTQmMeta?.alias || [],
    redirect: indexdBWd3mZTQmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_plan_id_93eKkkB7vMEVMeta?.name ?? undefined,
    meta: _91account_plan_id_93eKkkB7vMEVMeta || {},
    alias: _91account_plan_id_93eKkkB7vMEVMeta?.alias || [],
    redirect: _91account_plan_id_93eKkkB7vMEVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_plans/[account_plan_id].vue").then(m => m.default || m)
  },
  {
    path: account_requested_plans5vdBZDs9GIMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93SEJGfYTgZdMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editkYrWCHZQwyMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id-edit",
    path: editkYrWCHZQwyMeta?.path ?? "edit",
    meta: editkYrWCHZQwyMeta || {},
    alias: editkYrWCHZQwyMeta?.alias || [],
    redirect: editkYrWCHZQwyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexTcuLHsQQ3eMeta?.name ?? "admin-account_requested_plans-account_requested_plan_id",
    path: indexTcuLHsQQ3eMeta?.path ?? "",
    meta: indexTcuLHsQQ3eMeta || {},
    alias: indexTcuLHsQQ3eMeta?.alias || [],
    redirect: indexTcuLHsQQ3eMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93SEJGfYTgZdMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93SEJGfYTgZdMeta || {},
    alias: _91account_requested_plan_id_93SEJGfYTgZdMeta?.alias || [],
    redirect: _91account_requested_plan_id_93SEJGfYTgZdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indextpXKCaQwfyMeta?.name ?? "admin-account_requested_plans",
    path: indextpXKCaQwfyMeta?.path ?? "",
    meta: indextpXKCaQwfyMeta || {},
    alias: indextpXKCaQwfyMeta?.alias || [],
    redirect: indextpXKCaQwfyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newiWb9a0clUvMeta?.name ?? "admin-account_requested_plans-new",
    path: newiWb9a0clUvMeta?.path ?? "new",
    meta: newiWb9a0clUvMeta || {},
    alias: newiWb9a0clUvMeta?.alias || [],
    redirect: newiWb9a0clUvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plans5vdBZDs9GIMeta?.name ?? undefined,
    meta: account_requested_plans5vdBZDs9GIMeta || {},
    alias: account_requested_plans5vdBZDs9GIMeta?.alias || [],
    redirect: account_requested_plans5vdBZDs9GIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: accountsp3RB3XgI6AMeta?.path ?? "accounts",
    children: [
  {
    path: _91account_id_93vhrOD7SWfhMeta?.path ?? ":account_id()",
    children: [
  {
    path: account_requested_plansfUxeiwy30sMeta?.path ?? "account_requested_plans",
    children: [
  {
    path: _91account_requested_plan_id_93Lp2jDSKpmAMeta?.path ?? ":account_requested_plan_id()",
    children: [
  {
    name: editBWR8AIpX4IMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id-edit",
    path: editBWR8AIpX4IMeta?.path ?? "edit",
    meta: editBWR8AIpX4IMeta || {},
    alias: editBWR8AIpX4IMeta?.alias || [],
    redirect: editBWR8AIpX4IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexX2KJj0n2nlMeta?.name ?? "admin-accounts-account_id-account_requested_plans-account_requested_plan_id",
    path: indexX2KJj0n2nlMeta?.path ?? "",
    meta: indexX2KJj0n2nlMeta || {},
    alias: indexX2KJj0n2nlMeta?.alias || [],
    redirect: indexX2KJj0n2nlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91account_requested_plan_id_93Lp2jDSKpmAMeta?.name ?? undefined,
    meta: _91account_requested_plan_id_93Lp2jDSKpmAMeta || {},
    alias: _91account_requested_plan_id_93Lp2jDSKpmAMeta?.alias || [],
    redirect: _91account_requested_plan_id_93Lp2jDSKpmAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/[account_requested_plan_id].vue").then(m => m.default || m)
  },
  {
    name: indexQjvE8NnxgAMeta?.name ?? "admin-accounts-account_id-account_requested_plans",
    path: indexQjvE8NnxgAMeta?.path ?? "",
    meta: indexQjvE8NnxgAMeta || {},
    alias: indexQjvE8NnxgAMeta?.alias || [],
    redirect: indexQjvE8NnxgAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/index.vue").then(m => m.default || m)
  },
  {
    name: newL1vbGvkDdXMeta?.name ?? "admin-accounts-account_id-account_requested_plans-new",
    path: newL1vbGvkDdXMeta?.path ?? "new",
    meta: newL1vbGvkDdXMeta || {},
    alias: newL1vbGvkDdXMeta?.alias || [],
    redirect: newL1vbGvkDdXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans/new.vue").then(m => m.default || m)
  }
],
    name: account_requested_plansfUxeiwy30sMeta?.name ?? undefined,
    meta: account_requested_plansfUxeiwy30sMeta || {},
    alias: account_requested_plansfUxeiwy30sMeta?.alias || [],
    redirect: account_requested_plansfUxeiwy30sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/account_requested_plans.vue").then(m => m.default || m)
  },
  {
    path: caller_idsQfP44mQrxnMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93PzLJRO7FVTMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editO6hoOLnRcLMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id-edit",
    path: editO6hoOLnRcLMeta?.path ?? "edit",
    meta: editO6hoOLnRcLMeta || {},
    alias: editO6hoOLnRcLMeta?.alias || [],
    redirect: editO6hoOLnRcLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexm2Fw7VIYNEMeta?.name ?? "admin-accounts-account_id-caller_ids-caller_id_id",
    path: indexm2Fw7VIYNEMeta?.path ?? "",
    meta: indexm2Fw7VIYNEMeta || {},
    alias: indexm2Fw7VIYNEMeta?.alias || [],
    redirect: indexm2Fw7VIYNEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93PzLJRO7FVTMeta?.name ?? undefined,
    meta: _91caller_id_id_93PzLJRO7FVTMeta || {},
    alias: _91caller_id_id_93PzLJRO7FVTMeta?.alias || [],
    redirect: _91caller_id_id_93PzLJRO7FVTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: index1pUbYFngHIMeta?.name ?? "admin-accounts-account_id-caller_ids",
    path: index1pUbYFngHIMeta?.path ?? "",
    meta: index1pUbYFngHIMeta || {},
    alias: index1pUbYFngHIMeta?.alias || [],
    redirect: index1pUbYFngHIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: new1EEdQTaPR4Meta?.name ?? "admin-accounts-account_id-caller_ids-new",
    path: new1EEdQTaPR4Meta?.path ?? "new",
    meta: new1EEdQTaPR4Meta || {},
    alias: new1EEdQTaPR4Meta?.alias || [],
    redirect: new1EEdQTaPR4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_idsQfP44mQrxnMeta?.name ?? undefined,
    meta: caller_idsQfP44mQrxnMeta || {},
    alias: caller_idsQfP44mQrxnMeta?.alias || [],
    redirect: caller_idsQfP44mQrxnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/caller_ids.vue").then(m => m.default || m)
  },
  {
    name: editTFFByvvE8pMeta?.name ?? "admin-accounts-account_id-edit",
    path: editTFFByvvE8pMeta?.path ?? "edit",
    meta: editTFFByvvE8pMeta || {},
    alias: editTFFByvvE8pMeta?.alias || [],
    redirect: editTFFByvvE8pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index7LAVJrLVzjMeta?.name ?? "admin-accounts-account_id",
    path: index7LAVJrLVzjMeta?.path ?? "",
    meta: index7LAVJrLVzjMeta || {},
    alias: index7LAVJrLVzjMeta?.alias || [],
    redirect: index7LAVJrLVzjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/index.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsdy6KKrrGwMMeta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_937qvIwDkynvMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit8be7oh5GcuMeta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id-edit",
    path: edit8be7oh5GcuMeta?.path ?? "edit",
    meta: edit8be7oh5GcuMeta || {},
    alias: edit8be7oh5GcuMeta?.alias || [],
    redirect: edit8be7oh5GcuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexMNpBywGyU2Meta?.name ?? "admin-accounts-account_id-sms_maskings-sms_masking_id",
    path: indexMNpBywGyU2Meta?.path ?? "",
    meta: indexMNpBywGyU2Meta || {},
    alias: indexMNpBywGyU2Meta?.alias || [],
    redirect: indexMNpBywGyU2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_937qvIwDkynvMeta?.name ?? undefined,
    meta: _91sms_masking_id_937qvIwDkynvMeta || {},
    alias: _91sms_masking_id_937qvIwDkynvMeta?.alias || [],
    redirect: _91sms_masking_id_937qvIwDkynvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexfsd52rfuLUMeta?.name ?? "admin-accounts-account_id-sms_maskings",
    path: indexfsd52rfuLUMeta?.path ?? "",
    meta: indexfsd52rfuLUMeta || {},
    alias: indexfsd52rfuLUMeta?.alias || [],
    redirect: indexfsd52rfuLUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newBatBYAg2imMeta?.name ?? "admin-accounts-account_id-sms_maskings-new",
    path: newBatBYAg2imMeta?.path ?? "new",
    meta: newBatBYAg2imMeta || {},
    alias: newBatBYAg2imMeta?.alias || [],
    redirect: newBatBYAg2imMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsdy6KKrrGwMMeta?.name ?? undefined,
    meta: sms_maskingsdy6KKrrGwMMeta || {},
    alias: sms_maskingsdy6KKrrGwMMeta?.alias || [],
    redirect: sms_maskingsdy6KKrrGwMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id]/sms_maskings.vue").then(m => m.default || m)
  }
],
    name: _91account_id_93vhrOD7SWfhMeta?.name ?? undefined,
    meta: _91account_id_93vhrOD7SWfhMeta || {},
    alias: _91account_id_93vhrOD7SWfhMeta?.alias || [],
    redirect: _91account_id_93vhrOD7SWfhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/[account_id].vue").then(m => m.default || m)
  },
  {
    name: indexUk63cPww4qMeta?.name ?? "admin-accounts",
    path: indexUk63cPww4qMeta?.path ?? "",
    meta: indexUk63cPww4qMeta || {},
    alias: indexUk63cPww4qMeta?.alias || [],
    redirect: indexUk63cPww4qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: wizard_plan_selectionj4tZohUzNnMeta?.name ?? "admin-accounts-wizard_plan_selection",
    path: wizard_plan_selectionj4tZohUzNnMeta?.path ?? "wizard_plan_selection",
    meta: wizard_plan_selectionj4tZohUzNnMeta || {},
    alias: wizard_plan_selectionj4tZohUzNnMeta?.alias || [],
    redirect: wizard_plan_selectionj4tZohUzNnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/wizard_plan_selection.vue").then(m => m.default || m)
  },
  {
    name: wizardr64n0OTo3uMeta?.name ?? "admin-accounts-wizard",
    path: wizardr64n0OTo3uMeta?.path ?? "wizard",
    meta: wizardr64n0OTo3uMeta || {},
    alias: wizardr64n0OTo3uMeta?.alias || [],
    redirect: wizardr64n0OTo3uMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts/wizard.vue").then(m => m.default || m)
  }
],
    name: accountsp3RB3XgI6AMeta?.name ?? undefined,
    meta: accountsp3RB3XgI6AMeta || {},
    alias: accountsp3RB3XgI6AMeta?.alias || [],
    redirect: accountsp3RB3XgI6AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    path: admin_usersEwHyO7tZoxMeta?.path ?? "admin_users",
    children: [
  {
    path: _91admin_user_id_93U1SqOdECv3Meta?.path ?? ":admin_user_id()",
    children: [
  {
    name: editHSkgWUYGgXMeta?.name ?? "admin-admin_users-admin_user_id-edit",
    path: editHSkgWUYGgXMeta?.path ?? "edit",
    meta: editHSkgWUYGgXMeta || {},
    alias: editHSkgWUYGgXMeta?.alias || [],
    redirect: editHSkgWUYGgXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRy5OAbiDVWMeta?.name ?? "admin-admin_users-admin_user_id",
    path: indexRy5OAbiDVWMeta?.path ?? "",
    meta: indexRy5OAbiDVWMeta || {},
    alias: indexRy5OAbiDVWMeta?.alias || [],
    redirect: indexRy5OAbiDVWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91admin_user_id_93U1SqOdECv3Meta?.name ?? undefined,
    meta: _91admin_user_id_93U1SqOdECv3Meta || {},
    alias: _91admin_user_id_93U1SqOdECv3Meta?.alias || [],
    redirect: _91admin_user_id_93U1SqOdECv3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users/[admin_user_id].vue").then(m => m.default || m)
  },
  {
    name: indexmOjFvpVr8IMeta?.name ?? "admin-admin_users",
    path: indexmOjFvpVr8IMeta?.path ?? "",
    meta: indexmOjFvpVr8IMeta || {},
    alias: indexmOjFvpVr8IMeta?.alias || [],
    redirect: indexmOjFvpVr8IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users/index.vue").then(m => m.default || m)
  },
  {
    name: newPWDzCSXZZ9Meta?.name ?? "admin-admin_users-new",
    path: newPWDzCSXZZ9Meta?.path ?? "new",
    meta: newPWDzCSXZZ9Meta || {},
    alias: newPWDzCSXZZ9Meta?.alias || [],
    redirect: newPWDzCSXZZ9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users/new.vue").then(m => m.default || m)
  }
],
    name: admin_usersEwHyO7tZoxMeta?.name ?? undefined,
    meta: admin_usersEwHyO7tZoxMeta || {},
    alias: admin_usersEwHyO7tZoxMeta?.alias || [],
    redirect: admin_usersEwHyO7tZoxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/admin_users.vue").then(m => m.default || m)
  },
  {
    path: audit_trails9QlENXk9BiMeta?.path ?? "audit_trails",
    children: [
  {
    name: indexMKewOiFFnZMeta?.name ?? "admin-audit_trails",
    path: indexMKewOiFFnZMeta?.path ?? "",
    meta: indexMKewOiFFnZMeta || {},
    alias: indexMKewOiFFnZMeta?.alias || [],
    redirect: indexMKewOiFFnZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/audit_trails/index.vue").then(m => m.default || m)
  }
],
    name: audit_trails9QlENXk9BiMeta?.name ?? undefined,
    meta: audit_trails9QlENXk9BiMeta || {},
    alias: audit_trails9QlENXk9BiMeta?.alias || [],
    redirect: audit_trails9QlENXk9BiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/audit_trails.vue").then(m => m.default || m)
  },
  {
    path: billingsLBjRSR8QCZMeta?.path ?? "billings",
    children: [
  {
    path: _91billing_id_93UJzBePCElVMeta?.path ?? ":billing_id()",
    children: [
  {
    name: editoeESQAbnedMeta?.name ?? "admin-billings-billing_id-edit",
    path: editoeESQAbnedMeta?.path ?? "edit",
    meta: editoeESQAbnedMeta || {},
    alias: editoeESQAbnedMeta?.alias || [],
    redirect: editoeESQAbnedMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings/[billing_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuM7EVeYS7IMeta?.name ?? "admin-billings-billing_id",
    path: indexuM7EVeYS7IMeta?.path ?? "",
    meta: indexuM7EVeYS7IMeta || {},
    alias: indexuM7EVeYS7IMeta?.alias || [],
    redirect: indexuM7EVeYS7IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings/[billing_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91billing_id_93UJzBePCElVMeta?.name ?? undefined,
    meta: _91billing_id_93UJzBePCElVMeta || {},
    alias: _91billing_id_93UJzBePCElVMeta?.alias || [],
    redirect: _91billing_id_93UJzBePCElVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings/[billing_id].vue").then(m => m.default || m)
  },
  {
    name: indext5flcA4kzNMeta?.name ?? "admin-billings",
    path: indext5flcA4kzNMeta?.path ?? "",
    meta: indext5flcA4kzNMeta || {},
    alias: indext5flcA4kzNMeta?.alias || [],
    redirect: indext5flcA4kzNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings/index.vue").then(m => m.default || m)
  },
  {
    name: newDVy90s9Gu8Meta?.name ?? "admin-billings-new",
    path: newDVy90s9Gu8Meta?.path ?? "new",
    meta: newDVy90s9Gu8Meta || {},
    alias: newDVy90s9Gu8Meta?.alias || [],
    redirect: newDVy90s9Gu8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings/new.vue").then(m => m.default || m)
  }
],
    name: billingsLBjRSR8QCZMeta?.name ?? undefined,
    meta: billingsLBjRSR8QCZMeta || {},
    alias: billingsLBjRSR8QCZMeta?.alias || [],
    redirect: billingsLBjRSR8QCZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/billings.vue").then(m => m.default || m)
  },
  {
    path: caller_ids6Rpy7UoohtMeta?.path ?? "caller_ids",
    children: [
  {
    path: _91caller_id_id_93wm2AQytXccMeta?.path ?? ":caller_id_id()",
    children: [
  {
    name: editAM4jOM8q3sMeta?.name ?? "admin-caller_ids-caller_id_id-edit",
    path: editAM4jOM8q3sMeta?.path ?? "edit",
    meta: editAM4jOM8q3sMeta || {},
    alias: editAM4jOM8q3sMeta?.alias || [],
    redirect: editAM4jOM8q3sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexxXVhSbPKTAMeta?.name ?? "admin-caller_ids-caller_id_id",
    path: indexxXVhSbPKTAMeta?.path ?? "",
    meta: indexxXVhSbPKTAMeta || {},
    alias: indexxXVhSbPKTAMeta?.alias || [],
    redirect: indexxXVhSbPKTAMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91caller_id_id_93wm2AQytXccMeta?.name ?? undefined,
    meta: _91caller_id_id_93wm2AQytXccMeta || {},
    alias: _91caller_id_id_93wm2AQytXccMeta?.alias || [],
    redirect: _91caller_id_id_93wm2AQytXccMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids/[caller_id_id].vue").then(m => m.default || m)
  },
  {
    name: indexYw5Ha6yhfrMeta?.name ?? "admin-caller_ids",
    path: indexYw5Ha6yhfrMeta?.path ?? "",
    meta: indexYw5Ha6yhfrMeta || {},
    alias: indexYw5Ha6yhfrMeta?.alias || [],
    redirect: indexYw5Ha6yhfrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids/index.vue").then(m => m.default || m)
  },
  {
    name: newEf3q2SMekhMeta?.name ?? "admin-caller_ids-new",
    path: newEf3q2SMekhMeta?.path ?? "new",
    meta: newEf3q2SMekhMeta || {},
    alias: newEf3q2SMekhMeta?.alias || [],
    redirect: newEf3q2SMekhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids/new.vue").then(m => m.default || m)
  }
],
    name: caller_ids6Rpy7UoohtMeta?.name ?? undefined,
    meta: caller_ids6Rpy7UoohtMeta || {},
    alias: caller_ids6Rpy7UoohtMeta?.alias || [],
    redirect: caller_ids6Rpy7UoohtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/caller_ids.vue").then(m => m.default || m)
  },
  {
    path: _91campaign_setting_id_93fAWGfcDP8LMeta?.path ?? "campaign_settings/:campaign_setting_id()",
    children: [
  {
    name: editGaaTlEoPfkMeta?.name ?? "admin-campaign_settings-campaign_setting_id-edit",
    path: editGaaTlEoPfkMeta?.path ?? "edit",
    meta: editGaaTlEoPfkMeta || {},
    alias: editGaaTlEoPfkMeta?.alias || [],
    redirect: editGaaTlEoPfkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexxTidS3RpbYMeta?.name ?? "admin-campaign_settings-campaign_setting_id",
    path: indexxTidS3RpbYMeta?.path ?? "",
    meta: indexxTidS3RpbYMeta || {},
    alias: indexxTidS3RpbYMeta?.alias || [],
    redirect: indexxTidS3RpbYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91campaign_setting_id_93fAWGfcDP8LMeta?.name ?? undefined,
    meta: _91campaign_setting_id_93fAWGfcDP8LMeta || {},
    alias: _91campaign_setting_id_93fAWGfcDP8LMeta?.alias || [],
    redirect: _91campaign_setting_id_93fAWGfcDP8LMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaign_settings/[campaign_setting_id].vue").then(m => m.default || m)
  },
  {
    path: campaignsqF1qMn7bL0Meta?.path ?? "campaigns",
    children: [
  {
    path: _91campaign_id_936A8v6NvYt4Meta?.path ?? ":campaign_id()",
    children: [
  {
    name: editcBRY9JFL8oMeta?.name ?? "admin-campaigns-campaign_id-edit",
    path: editcBRY9JFL8oMeta?.path ?? "edit",
    meta: editcBRY9JFL8oMeta || {},
    alias: editcBRY9JFL8oMeta?.alias || [],
    redirect: editcBRY9JFL8oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index43YG92QblaMeta?.name ?? "admin-campaigns-campaign_id",
    path: index43YG92QblaMeta?.path ?? "",
    meta: index43YG92QblaMeta || {},
    alias: index43YG92QblaMeta?.alias || [],
    redirect: index43YG92QblaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/index.vue").then(m => m.default || m)
  },
  {
    path: outbound_call_transactionsWbnYvNcguGMeta?.path ?? "outbound_call_transactions",
    children: [
  {
    path: _91outbound_call_transaction_id_9329cvb5itgZMeta?.path ?? ":outbound_call_transaction_id()",
    children: [
  {
    name: edit515jWxMHEIMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id-edit",
    path: edit515jWxMHEIMeta?.path ?? "edit",
    meta: edit515jWxMHEIMeta || {},
    alias: edit515jWxMHEIMeta?.alias || [],
    redirect: edit515jWxMHEIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexf6fTg6lIR4Meta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-outbound_call_transaction_id",
    path: indexf6fTg6lIR4Meta?.path ?? "",
    meta: indexf6fTg6lIR4Meta || {},
    alias: indexf6fTg6lIR4Meta?.alias || [],
    redirect: indexf6fTg6lIR4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91outbound_call_transaction_id_9329cvb5itgZMeta?.name ?? undefined,
    meta: _91outbound_call_transaction_id_9329cvb5itgZMeta || {},
    alias: _91outbound_call_transaction_id_9329cvb5itgZMeta?.alias || [],
    redirect: _91outbound_call_transaction_id_9329cvb5itgZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/[outbound_call_transaction_id].vue").then(m => m.default || m)
  },
  {
    name: indexvPlpPib0uDMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions",
    path: indexvPlpPib0uDMeta?.path ?? "",
    meta: indexvPlpPib0uDMeta || {},
    alias: indexvPlpPib0uDMeta?.alias || [],
    redirect: indexvPlpPib0uDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/index.vue").then(m => m.default || m)
  },
  {
    name: newHKycfo6yYrMeta?.name ?? "admin-campaigns-campaign_id-outbound_call_transactions-new",
    path: newHKycfo6yYrMeta?.path ?? "new",
    meta: newHKycfo6yYrMeta || {},
    alias: newHKycfo6yYrMeta?.alias || [],
    redirect: newHKycfo6yYrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions/new.vue").then(m => m.default || m)
  }
],
    name: outbound_call_transactionsWbnYvNcguGMeta?.name ?? undefined,
    meta: outbound_call_transactionsWbnYvNcguGMeta || {},
    alias: outbound_call_transactionsWbnYvNcguGMeta?.alias || [],
    redirect: outbound_call_transactionsWbnYvNcguGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id]/outbound_call_transactions.vue").then(m => m.default || m)
  }
],
    name: _91campaign_id_936A8v6NvYt4Meta?.name ?? undefined,
    meta: _91campaign_id_936A8v6NvYt4Meta || {},
    alias: _91campaign_id_936A8v6NvYt4Meta?.alias || [],
    redirect: _91campaign_id_936A8v6NvYt4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/[campaign_id].vue").then(m => m.default || m)
  },
  {
    name: index3SeP6to11rMeta?.name ?? "admin-campaigns",
    path: index3SeP6to11rMeta?.path ?? "",
    meta: index3SeP6to11rMeta || {},
    alias: index3SeP6to11rMeta?.alias || [],
    redirect: index3SeP6to11rMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newDHTihawExxMeta?.name ?? "admin-campaigns-new",
    path: newDHTihawExxMeta?.path ?? "new",
    meta: newDHTihawExxMeta || {},
    alias: newDHTihawExxMeta?.alias || [],
    redirect: newDHTihawExxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsqF1qMn7bL0Meta?.name ?? undefined,
    meta: campaignsqF1qMn7bL0Meta || {},
    alias: campaignsqF1qMn7bL0Meta?.alias || [],
    redirect: campaignsqF1qMn7bL0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/campaigns.vue").then(m => m.default || m)
  },
  {
    path: custom_emailsQM0CyevzSfMeta?.path ?? "custom_emails",
    children: [
  {
    path: _91custom_email_id_93goyPqs8XzdMeta?.path ?? ":custom_email_id()",
    children: [
  {
    name: indexHzlrOiMp1XMeta?.name ?? "admin-custom_emails-custom_email_id",
    path: indexHzlrOiMp1XMeta?.path ?? "",
    meta: indexHzlrOiMp1XMeta || {},
    alias: indexHzlrOiMp1XMeta?.alias || [],
    redirect: indexHzlrOiMp1XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/custom_emails/[custom_email_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91custom_email_id_93goyPqs8XzdMeta?.name ?? undefined,
    meta: _91custom_email_id_93goyPqs8XzdMeta || {},
    alias: _91custom_email_id_93goyPqs8XzdMeta?.alias || [],
    redirect: _91custom_email_id_93goyPqs8XzdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/custom_emails/[custom_email_id].vue").then(m => m.default || m)
  },
  {
    name: indexokPEUCs8l7Meta?.name ?? "admin-custom_emails",
    path: indexokPEUCs8l7Meta?.path ?? "",
    meta: indexokPEUCs8l7Meta || {},
    alias: indexokPEUCs8l7Meta?.alias || [],
    redirect: indexokPEUCs8l7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/custom_emails/index.vue").then(m => m.default || m)
  },
  {
    name: newutxkkO9qYPMeta?.name ?? "admin-custom_emails-new",
    path: newutxkkO9qYPMeta?.path ?? "new",
    meta: newutxkkO9qYPMeta || {},
    alias: newutxkkO9qYPMeta?.alias || [],
    redirect: newutxkkO9qYPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/custom_emails/new.vue").then(m => m.default || m)
  }
],
    name: custom_emailsQM0CyevzSfMeta?.name ?? undefined,
    meta: custom_emailsQM0CyevzSfMeta || {},
    alias: custom_emailsQM0CyevzSfMeta?.alias || [],
    redirect: custom_emailsQM0CyevzSfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/custom_emails.vue").then(m => m.default || m)
  },
  {
    path: dashboard9IcS2NYvnyMeta?.path ?? "dashboard",
    children: [
  {
    name: indexRlxJzvFcDeMeta?.name ?? "admin-dashboard",
    path: indexRlxJzvFcDeMeta?.path ?? "",
    meta: indexRlxJzvFcDeMeta || {},
    alias: indexRlxJzvFcDeMeta?.alias || [],
    redirect: indexRlxJzvFcDeMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboard9IcS2NYvnyMeta?.name ?? undefined,
    meta: dashboard9IcS2NYvnyMeta || {},
    alias: dashboard9IcS2NYvnyMeta?.alias || [],
    redirect: dashboard9IcS2NYvnyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    path: inquiriesWCjEGPdeblMeta?.path ?? "inquiries",
    children: [
  {
    path: _91inquiry_id_93WCsY8k6x0AMeta?.path ?? ":inquiry_id()",
    children: [
  {
    name: index3StMTQ9j1ZMeta?.name ?? "admin-inquiries-inquiry_id",
    path: index3StMTQ9j1ZMeta?.path ?? "",
    meta: index3StMTQ9j1ZMeta || {},
    alias: index3StMTQ9j1ZMeta?.alias || [],
    redirect: index3StMTQ9j1ZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/inquiries/[inquiry_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91inquiry_id_93WCsY8k6x0AMeta?.name ?? undefined,
    meta: _91inquiry_id_93WCsY8k6x0AMeta || {},
    alias: _91inquiry_id_93WCsY8k6x0AMeta?.alias || [],
    redirect: _91inquiry_id_93WCsY8k6x0AMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/inquiries/[inquiry_id].vue").then(m => m.default || m)
  },
  {
    name: indexJpnz3lZDIjMeta?.name ?? "admin-inquiries",
    path: indexJpnz3lZDIjMeta?.path ?? "",
    meta: indexJpnz3lZDIjMeta || {},
    alias: indexJpnz3lZDIjMeta?.alias || [],
    redirect: indexJpnz3lZDIjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/inquiries/index.vue").then(m => m.default || m)
  },
  {
    name: newIIjLXaJWkQMeta?.name ?? "admin-inquiries-new",
    path: newIIjLXaJWkQMeta?.path ?? "new",
    meta: newIIjLXaJWkQMeta || {},
    alias: newIIjLXaJWkQMeta?.alias || [],
    redirect: newIIjLXaJWkQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/inquiries/new.vue").then(m => m.default || m)
  }
],
    name: inquiriesWCjEGPdeblMeta?.name ?? undefined,
    meta: inquiriesWCjEGPdeblMeta || {},
    alias: inquiriesWCjEGPdeblMeta?.alias || [],
    redirect: inquiriesWCjEGPdeblMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/inquiries.vue").then(m => m.default || m)
  },
  {
    path: loginD4TrqyZLtFMeta?.path ?? "login",
    children: [
  {
    name: indexaecGqEenMQMeta?.name ?? "admin-login",
    path: indexaecGqEenMQMeta?.path ?? "",
    meta: indexaecGqEenMQMeta || {},
    alias: indexaecGqEenMQMeta?.alias || [],
    redirect: indexaecGqEenMQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/login/index.vue").then(m => m.default || m)
  }
],
    name: loginD4TrqyZLtFMeta?.name ?? undefined,
    meta: loginD4TrqyZLtFMeta || {},
    alias: loginD4TrqyZLtFMeta?.alias || [],
    redirect: loginD4TrqyZLtFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: index0iKiPPdpILMeta?.name ?? "admin-password-edit",
    path: index0iKiPPdpILMeta?.path ?? "password/edit",
    meta: index0iKiPPdpILMeta || {},
    alias: index0iKiPPdpILMeta?.alias || [],
    redirect: index0iKiPPdpILMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: index7gPgjfjhVOMeta?.name ?? "admin-password-new",
    path: index7gPgjfjhVOMeta?.path ?? "password/new",
    meta: index7gPgjfjhVOMeta || {},
    alias: index7gPgjfjhVOMeta?.alias || [],
    redirect: index7gPgjfjhVOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/password/new/index.vue").then(m => m.default || m)
  },
  {
    path: reportsaMWgpMfDd6Meta?.path ?? "reports",
    children: [
  {
    name: indexnuahQpaYo1Meta?.name ?? "admin-reports",
    path: indexnuahQpaYo1Meta?.path ?? "",
    meta: indexnuahQpaYo1Meta || {},
    alias: indexnuahQpaYo1Meta?.alias || [],
    redirect: indexnuahQpaYo1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsaMWgpMfDd6Meta?.name ?? undefined,
    meta: reportsaMWgpMfDd6Meta || {},
    alias: reportsaMWgpMfDd6Meta?.alias || [],
    redirect: reportsaMWgpMfDd6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/reports.vue").then(m => m.default || m)
  },
  {
    path: sms_maskingsFJfvT3c8s2Meta?.path ?? "sms_maskings",
    children: [
  {
    path: _91sms_masking_id_93Mk0fjr6N0wMeta?.path ?? ":sms_masking_id()",
    children: [
  {
    name: edit8j7Pim3Ou3Meta?.name ?? "admin-sms_maskings-sms_masking_id-edit",
    path: edit8j7Pim3Ou3Meta?.path ?? "edit",
    meta: edit8j7Pim3Ou3Meta || {},
    alias: edit8j7Pim3Ou3Meta?.alias || [],
    redirect: edit8j7Pim3Ou3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexhqle2kyvKVMeta?.name ?? "admin-sms_maskings-sms_masking_id",
    path: indexhqle2kyvKVMeta?.path ?? "",
    meta: indexhqle2kyvKVMeta || {},
    alias: indexhqle2kyvKVMeta?.alias || [],
    redirect: indexhqle2kyvKVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91sms_masking_id_93Mk0fjr6N0wMeta?.name ?? undefined,
    meta: _91sms_masking_id_93Mk0fjr6N0wMeta || {},
    alias: _91sms_masking_id_93Mk0fjr6N0wMeta?.alias || [],
    redirect: _91sms_masking_id_93Mk0fjr6N0wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings/[sms_masking_id].vue").then(m => m.default || m)
  },
  {
    name: indexbP4fepu0FgMeta?.name ?? "admin-sms_maskings",
    path: indexbP4fepu0FgMeta?.path ?? "",
    meta: indexbP4fepu0FgMeta || {},
    alias: indexbP4fepu0FgMeta?.alias || [],
    redirect: indexbP4fepu0FgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings/index.vue").then(m => m.default || m)
  },
  {
    name: newg37jdDkLIlMeta?.name ?? "admin-sms_maskings-new",
    path: newg37jdDkLIlMeta?.path ?? "new",
    meta: newg37jdDkLIlMeta || {},
    alias: newg37jdDkLIlMeta?.alias || [],
    redirect: newg37jdDkLIlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings/new.vue").then(m => m.default || m)
  }
],
    name: sms_maskingsFJfvT3c8s2Meta?.name ?? undefined,
    meta: sms_maskingsFJfvT3c8s2Meta || {},
    alias: sms_maskingsFJfvT3c8s2Meta?.alias || [],
    redirect: sms_maskingsFJfvT3c8s2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/sms_maskings.vue").then(m => m.default || m)
  },
  {
    path: usersmVr851jO3YMeta?.path ?? "users",
    children: [
  {
    path: _91user_id_932dyGTFt8U8Meta?.path ?? ":user_id()",
    children: [
  {
    name: editGDfsfbtfFLMeta?.name ?? "admin-users-user_id-edit",
    path: editGDfsfbtfFLMeta?.path ?? "edit",
    meta: editGDfsfbtfFLMeta || {},
    alias: editGDfsfbtfFLMeta?.alias || [],
    redirect: editGDfsfbtfFLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjcT5gFSpW1Meta?.name ?? "admin-users-user_id",
    path: indexjcT5gFSpW1Meta?.path ?? "",
    meta: indexjcT5gFSpW1Meta || {},
    alias: indexjcT5gFSpW1Meta?.alias || [],
    redirect: indexjcT5gFSpW1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users/[user_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91user_id_932dyGTFt8U8Meta?.name ?? undefined,
    meta: _91user_id_932dyGTFt8U8Meta || {},
    alias: _91user_id_932dyGTFt8U8Meta?.alias || [],
    redirect: _91user_id_932dyGTFt8U8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users/[user_id].vue").then(m => m.default || m)
  },
  {
    name: index7DIu9MCuWBMeta?.name ?? "admin-users",
    path: index7DIu9MCuWBMeta?.path ?? "",
    meta: index7DIu9MCuWBMeta || {},
    alias: index7DIu9MCuWBMeta?.alias || [],
    redirect: index7DIu9MCuWBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newXqoyuS2B0sMeta?.name ?? "admin-users-new",
    path: newXqoyuS2B0sMeta?.path ?? "new",
    meta: newXqoyuS2B0sMeta || {},
    alias: newXqoyuS2B0sMeta?.alias || [],
    redirect: newXqoyuS2B0sMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users/new.vue").then(m => m.default || m)
  }
],
    name: usersmVr851jO3YMeta?.name ?? undefined,
    meta: usersmVr851jO3YMeta || {},
    alias: usersmVr851jO3YMeta?.alias || [],
    redirect: usersmVr851jO3YMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    path: _91wallet_id_93xLq1VeTySDMeta?.path ?? "wallets/:wallet_id()",
    children: [
  {
    name: editJHI75mK9rUMeta?.name ?? "admin-wallets-wallet_id-edit",
    path: editJHI75mK9rUMeta?.path ?? "edit",
    meta: editJHI75mK9rUMeta || {},
    alias: editJHI75mK9rUMeta?.alias || [],
    redirect: editJHI75mK9rUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexXuuepJHAXYMeta?.name ?? "admin-wallets-wallet_id",
    path: indexXuuepJHAXYMeta?.path ?? "",
    meta: indexXuuepJHAXYMeta || {},
    alias: indexXuuepJHAXYMeta?.alias || [],
    redirect: indexXuuepJHAXYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id]/index.vue").then(m => m.default || m)
  }
],
    name: _91wallet_id_93xLq1VeTySDMeta?.name ?? undefined,
    meta: _91wallet_id_93xLq1VeTySDMeta || {},
    alias: _91wallet_id_93xLq1VeTySDMeta?.alias || [],
    redirect: _91wallet_id_93xLq1VeTySDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin/wallets/[wallet_id].vue").then(m => m.default || m)
  }
],
    meta: admind3Umya4o0QMeta || {},
    alias: admind3Umya4o0QMeta?.alias || [],
    redirect: admind3Umya4o0QMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexsByaFbKcXEMeta?.name ?? "audit_logs",
    path: indexsByaFbKcXEMeta?.path ?? "/audit_logs",
    meta: indexsByaFbKcXEMeta || {},
    alias: indexsByaFbKcXEMeta?.alias || [],
    redirect: indexsByaFbKcXEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: indexFFMQWeWY9GMeta?.name ?? "billings",
    path: indexFFMQWeWY9GMeta?.path ?? "/billings",
    meta: indexFFMQWeWY9GMeta || {},
    alias: indexFFMQWeWY9GMeta?.alias || [],
    redirect: indexFFMQWeWY9GMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/billings/index.vue").then(m => m.default || m)
  },
  {
    name: indexboWsqWCrzmMeta?.name ?? "call_out-reports",
    path: indexboWsqWCrzmMeta?.path ?? "/call_out/reports",
    meta: indexboWsqWCrzmMeta || {},
    alias: indexboWsqWCrzmMeta?.alias || [],
    redirect: indexboWsqWCrzmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/call_out/reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_935mjs8IcuawMeta?.name ?? "call_recordings-id",
    path: _91id_935mjs8IcuawMeta?.path ?? "/call_recordings/:id()",
    meta: _91id_935mjs8IcuawMeta || {},
    alias: _91id_935mjs8IcuawMeta?.alias || [],
    redirect: _91id_935mjs8IcuawMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/call_recordings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiJAUiY3rPtMeta?.name ?? "campaigns-id",
    path: indexiJAUiY3rPtMeta?.path ?? "/campaigns/:id()",
    meta: indexiJAUiY3rPtMeta || {},
    alias: indexiJAUiY3rPtMeta?.alias || [],
    redirect: indexiJAUiY3rPtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/campaigns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIBthkneu5Meta?.name ?? "campaigns",
    path: indexzIBthkneu5Meta?.path ?? "/campaigns",
    meta: indexzIBthkneu5Meta || {},
    alias: indexzIBthkneu5Meta?.alias || [],
    redirect: indexzIBthkneu5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMdhTRooqaMeta?.name ?? "dashboard",
    path: indexHMdhTRooqaMeta?.path ?? "/dashboard",
    meta: indexHMdhTRooqaMeta || {},
    alias: indexHMdhTRooqaMeta?.alias || [],
    redirect: indexHMdhTRooqaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexjbeRnpRnd7Meta?.name ?? "help",
    path: indexjbeRnpRnd7Meta?.path ?? "/help",
    meta: indexjbeRnpRnd7Meta || {},
    alias: indexjbeRnpRnd7Meta?.alias || [],
    redirect: indexjbeRnpRnd7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: index1XhcxyfxByMeta?.name ?? "index",
    path: index1XhcxyfxByMeta?.path ?? "/",
    meta: index1XhcxyfxByMeta || {},
    alias: index1XhcxyfxByMeta?.alias || [],
    redirect: index1XhcxyfxByMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGljENlFDjDMeta?.name ?? "inquiry",
    path: indexGljENlFDjDMeta?.path ?? "/inquiry",
    meta: indexGljENlFDjDMeta || {},
    alias: indexGljENlFDjDMeta?.alias || [],
    redirect: indexGljENlFDjDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: editorkCHpyu9ZtmMeta?.name ?? "ivr_trees-id-editor",
    path: editorkCHpyu9ZtmMeta?.path ?? "/ivr_trees/:id()/editor",
    meta: editorkCHpyu9ZtmMeta || {},
    alias: editorkCHpyu9ZtmMeta?.alias || [],
    redirect: editorkCHpyu9ZtmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/ivr_trees/[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: indexSBEAN7AJecMeta?.name ?? "ivr_trees",
    path: indexSBEAN7AJecMeta?.path ?? "/ivr_trees",
    meta: indexSBEAN7AJecMeta || {},
    alias: indexSBEAN7AJecMeta?.alias || [],
    redirect: indexSBEAN7AJecMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/ivr_trees/index.vue").then(m => m.default || m)
  },
  {
    name: verify_emailtRIpfTyIrcMeta?.name ?? "profiles-user_id-verify_email",
    path: verify_emailtRIpfTyIrcMeta?.path ?? "/profiles/:user_id()/verify_email",
    meta: verify_emailtRIpfTyIrcMeta || {},
    alias: verify_emailtRIpfTyIrcMeta?.alias || [],
    redirect: verify_emailtRIpfTyIrcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/profiles/[user_id]/verify_email.vue").then(m => m.default || m)
  },
  {
    name: welcome_dashboardO6rA3dnyHlMeta?.name ?? "profiles-user_id-welcome_dashboard",
    path: welcome_dashboardO6rA3dnyHlMeta?.path ?? "/profiles/:user_id()/welcome_dashboard",
    meta: welcome_dashboardO6rA3dnyHlMeta || {},
    alias: welcome_dashboardO6rA3dnyHlMeta?.alias || [],
    redirect: welcome_dashboardO6rA3dnyHlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/profiles/[user_id]/welcome_dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexPIegKiarypMeta?.name ?? "profiles",
    path: indexPIegKiarypMeta?.path ?? "/profiles",
    meta: indexPIegKiarypMeta || {},
    alias: indexPIegKiarypMeta?.alias || [],
    redirect: indexPIegKiarypMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: index7XYe9GPNmCMeta?.name ?? "sub_users-users",
    path: index7XYe9GPNmCMeta?.path ?? "/sub_users/users",
    meta: index7XYe9GPNmCMeta || {},
    alias: index7XYe9GPNmCMeta?.alias || [],
    redirect: index7XYe9GPNmCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/sub_users/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexH20yTe832lMeta?.name ?? "terms_and_conditions",
    path: indexH20yTe832lMeta?.path ?? "/terms_and_conditions",
    meta: indexH20yTe832lMeta || {},
    alias: indexH20yTe832lMeta?.alias || [],
    redirect: indexH20yTe832lMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/terms_and_conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexO4Lzx5U6ArMeta?.name ?? "users-password-edit",
    path: indexO4Lzx5U6ArMeta?.path ?? "/users/password/edit",
    meta: indexO4Lzx5U6ArMeta || {},
    alias: indexO4Lzx5U6ArMeta?.alias || [],
    redirect: indexO4Lzx5U6ArMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/users/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexWn9KwqF9g4Meta?.name ?? "users-password-new",
    path: indexWn9KwqF9g4Meta?.path ?? "/users/password/new",
    meta: indexWn9KwqF9g4Meta || {},
    alias: indexWn9KwqF9g4Meta?.alias || [],
    redirect: indexWn9KwqF9g4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/users/password/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzDhWR96yRMeta?.name ?? "users-sign_in",
    path: indexbzDhWR96yRMeta?.path ?? "/users/sign_in",
    meta: indexbzDhWR96yRMeta || {},
    alias: indexbzDhWR96yRMeta?.alias || [],
    redirect: indexbzDhWR96yRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src77872522/src/pages/users/sign_in/index.vue").then(m => m.default || m)
  }
]