import { z } from "zod"
import { empty, pick, transformers } from "@schtappe/utils"

import * as Definitions from "../models/definitions.js"
import * as Shared from "../models/shared.js"
import { formatters } from "../helpers/admin.js"

const { Integer, Decimal, Encrypted } = Definitions

const attributes = {
        id: { type: Integer, required: true, default: 1, form: false },
        account_id: { type: Integer, default: 1 },
        first_name: { type: String, required: true },
        last_name: { type: String, required: true },
        email: { type: String, required: true },
        mobile: { type: String, required: true },
        designation: { type: String, nullable: true },
        role_id: { type: Integer, default: 2 },
        password: { type: String, required: true },
        password_confirmation: { type: String },
        current_sign_in_at: { type: Date, nullable: true, form: false },
        last_sign_in_at: { type: Date, nullable: true, form: false },
        last_sign_in_ip: { type: String, nullable: true, form: false },
        created_at: { type: Date, form: false },
        updated_at: { type: Date, form: false },
        discarded_at: { type: Date, nullable: true, form: false },
}

const filters = [
        { key: "account", group: "select_model", option_key: "accounts" },
        { key: "first_name", group: "text" },
        { key: "last_name", group: "text" },
        { key: "email", group: "text" },
        { key: "mobile_number", group: "text" },
        { key: "created_at", group: "date" },
]

const Request = ({ helpers, config }) => ({
        Create: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm({
                                ...pick(["key", "attributes"], config),
                                append: { access_rights_attributes: [] },
                        })
                }

                const model = helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes,
                })
                const form = z.object({
                        ...model,
                        access_rights_attributes: z.object({
                                module: z.number(),
                                permission: z.number(),
                                enabled: z.boolean(),
                        }).array(),
                })
                return z.object({ [config.key]: form }).safeParse(data)
        },
        List: (data) => z.object({
                ...Shared.Request.Paginated(),
                q: z.object(helpers.createFiltersParser({ filters: config.filters }))
                        .optional()
                        .default({}),
        })
                .transform(({ q, ...data }) => ({
                        ...data,
                        ...helpers.toQueryObject({ query: q, filters: config.filters }),
                }))
                .safeParse(data),
        Update: (data) => {
                if (data == Symbol.for("empty")) {
                        return helpers.createEmptyForm({
                                ...pick(["key", "attributes"], config),
                                append: { access_rights_attributes: [] },
                        })
                }

                const model = helpers.modelToParser({
                        exclude: Object.entries(attributes).filter(([key, value]) => value.form === false).map(([key]) => key),
                        attributes,
                })
                const form = z.object({
                        ...model,
                        access_rights_attributes: z.object({
                                id: z.number(),
                                module: z.number(),
                                permission: z.number(),
                                enabled: z.boolean(),
                        }).array(),
                })
                return z.object({ [config.key]: form }).safeParse(data)
        },
})

const Response = ({ helpers, config }) => ({
        FormOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
                roles: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        Create: z.object({
                id: z.number(),
        }).safeParse,
        AccessRightsList: z.object({
                module_id: z.number(),
                module_name: z.string(),
                permission_id: z.number(),
                permission_name: z.string(),
                enabled: z.boolean(),
        }).array().safeParse,
        FilterOptions: z.object({
                accounts: z.tuple([z.string("name"), z.number("id")]).array(),
        }).safeParse,
        List: z.object({
                data: z.object({
                        id: z.number(),
                        account: z.object({
                                id: z.number(),
                                name: z.string(),
                        }).optional(),
                        email: z.string(),
                        first_name: z.string(),
                        last_name: z.string(),
                        created_at: z.coerce.date({ offset: true }),
                }).array(),
                ...Shared.Response.Paginated(),
        }).safeParse,
        Show: z.object({
                full_name: z.string(),
                user_details: z.object({
                        ...helpers.modelToParser({ exclude: ["password", "password_confirmation"], attributes }),
                        account: z.string(),
                        status: z.string(),
                        role: z.string(),
                }),
                access_rights: z.object({
                        id: z.number(),
                        module_id: z.number(),
                        module_name: z.string(),
                        permission_id: z.number(),
                        permission_name: z.string(),
                        enabled: z.boolean(),
                }).array(),
        }).safeParse,
})

const transformer = ([attribute, value]) => {
        const result = {}
        switch (attribute) {
                case "account_id": {
                        result.title = "Account"
                        result.options = []
                        result.option_key = "accounts"
                } break
                case "mobile": {
                        result.title = "Mobile number"
                } break
                case "role_id": {
                        result.title = "Role"
                        result.options = []
                        result.option_key = "roles"
                } break
        }
        return result
}

const Form = ({ helpers, config }) => ({
        Create: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
        Update: helpers.generateFormFields({
                attributes: config.attributes,
                transformer: transformer,
        }),
})

const Api = ({ helpers, config }) => ({
        FormOptions: {
                successParser: config.Response.FormOptions,
        },
        Create: {
                bodyParser: (options = { form: {} }) => config.Request.Create({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
                successParser: config.Response.Create,
        },
        FilterOptions: {
                successParser: config.Response.FilterOptions,
        },
        List: {
                queryParser: config.Request.List,
                successParser: config.Response.List,
        },
        Count: {
                successParser: config.Response.Count,
        },
        Show: {
                successParser: config.Response.Show,
        },
        Update: {
                bodyParser: (options = { form: {} }) => config.Request.Update({
                        [config.key]: options.form,
                }),
                errorParser: Shared.Response.Errors,
        },
})

const TableHeaders = [
        { title: "Id", key: "id" },
        {
                title: "Account",
                key: "account",
                getLink: ({ origin, item }) => item?.account?.id
                        ? (new URL(`/admin/accounts/${item.account.id}`, origin)).href
                        : "",
                format: ({ account }) => account?.name,
        },
        { title: "Email", key: "email" },
        { title: "First Name", key: "first_name" },
        { title: "Last Name", key: "last_name" },
        { key: "created_at", format: formatters.date },
]

const Pages = {
        show: {
                title: ({ resource }) => resource.full_name,
                actions: [
                        { name: "Edit User", path: "edit" },
                ],
                groups: [
                        {
                                name: "User Details",
                                type: "property",
                                value: (resource) => {
                                        const result = pick([
                                                "account",
                                                "first_name",
                                                "last_name",
                                                "email",
                                                "mobile",
                                                "role",
                                                "designation",
                                                "status",
                                                "current_sign_in_at",
                                                "last_sign_in_at",
                                                "last_sign_in_ip",
                                                "created_at",
                                                "updated_at",
                                                "discarded_at",
                                        ], resource.user_details)

                                        result.account = { link: `/admin/accounts/${resource?.user_details?.account_id}`, value: result.account }

                                        return result
                                },
                        },
                        {
                                name: "Access Rights",
                                type: "table",
                                headers: () => [
                                        { title: "Module", key: "module_name" },
                                        { title: "Permission", key: "permission_name" },
                                        { key: "enabled" },
                                ],
                                items: (resource) => resource?.access_rights,
                        },
                ],
        },
}

const resourceToForm = (resource) => {
        return {
                ...resource?.user_details,
                access_rights_attributes: resource?.access_rights?.map((access_right) => {
                        const result = {}

                        result.id = access_right.id

                        result.module = access_right.module_id
                        result.module_name = access_right.module_name

                        result.permission = access_right.permission_id
                        result.permission_name = access_right.permission_name

                        result.enabled = access_right.enabled

                        return result
                }),
        }
}

export default Shared.defineAdminModel({
        key: "user",
        attributes,
        filters,
        Request,
        Response,
        Form,
        Api,
        TableHeaders,
        Pages,
        resourceToForm,
})
